import CheckboxAlt from "components/CheckboxAlt";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext } from "react";

const AvailableForRegistrationField = () => {
  const {
    state: {
      fields: { enrollmentStatus },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  return (
    <div className="ml-2 flex items-center gap-2">
      <CheckboxAlt
        ariaLabelledby="Only Available for Registration"
        id="cs-available-for-registration-checkbox"
        value={enrollmentStatus === "Open"}
        onChange={(newValue) => {
          dispatch({
            type: "UPDATE_ENROLLMENT_STATUS",
            payload: {
              enrollmentStatus: newValue ? "Open" : "Closed",
            },
          });
        }}
      />
      <p id="AvailRegText" className="text-[12px] font-bold">
        Only Available for Registration
      </p>
    </div>
  );
};

export default AvailableForRegistrationField;
