import clsx from "clsx";
import { resetAllEventChannel } from "pages/newClassSearch/events/resetAllEventMsg";
import React, { Fragment, useContext, useEffect, useState } from "react";

import { ClassSearchContext } from "../../classSearchContext";

type BucketType = "online" | "onsite" | "virtual synchronous";

interface ClassFormat {
  classFormatCode: string;
  classFormatName: string;
}

export interface Bucket {
  type: BucketType;
  description: string;
  classFormatList: ClassFormat[];
  isSelected: boolean;
}

const OnlineBucket: Bucket = {
  type: "online",
  description: "Classes entirely online.",
  classFormatList: [
    {
      classFormatCode: "WT",
      classFormatName: "Online via the Web",
    },
  ],
  isSelected: false,
};
const OnsiteBucket: Bucket = {
  type: "onsite",
  description:
    "In-person classes and hybrid classes with specific meeting times.",
  classFormatList: [
    {
      classFormatCode: "HY",
      classFormatName: "Hybrid",
    },
    {
      classFormatCode: "P",
      classFormatName: "Face-to-Face",
    },
    {
      classFormatCode: "LH",
      classFormatName: "Hybrid Live Stream",
    },
    {
      classFormatCode: "HT",
      classFormatName: "Hybrid Tethered/Blended",
    },
    {
      classFormatCode: "PT",
      classFormatName: "Face-to-Face Tethered/Blended",
    },
    {
      classFormatCode: "LP",
      classFormatName: "Face-to-Face Live Stream",
    },
    {
      classFormatCode: "LT",
      classFormatName: "Live Stream Tethered/Blended",
    },
    {
      classFormatCode: "FS",
      classFormatName: "Field Study",
    },
    {
      classFormatCode: "HZ",
      classFormatName: "Hybrid Zoom",
    },
  ],
  isSelected: false,
};
const VirtualBucket: Bucket = {
  type: "virtual synchronous",
  description: "Virtual classes with specific meeting times.",
  classFormatList: [
    {
      classFormatCode: "VO",
      classFormatName: "Virtual Online",
    },
    {
      classFormatCode: "VT",
      classFormatName: "Virtual Tethered/Blended",
    },
  ],
  isSelected: false,
};

const ClassFormatField = () => {
  const {
    dispatch,
    state: {
      fields: { campusCode },
    },
  } = useContext(ClassSearchContext);

  const [bucketList, setBucketList] = useState<Bucket[]>([
    OnlineBucket,
    OnsiteBucket,
    VirtualBucket,
  ]);

  useEffect(() => {
    const classFormatCodeList: string[] = bucketList
      .filter((b) => b.isSelected)
      .flatMap((b) => b.classFormatList)
      .map((cf) => cf.classFormatCode);

    dispatch({
      type: "UPDATE_CLASS_FORMAT_CODE_LIST",
      payload: {
        classFormatCodeList,
      },
    });
  }, [bucketList]);

  useEffect(() => {
    const unsubscribeOnResetAll = resetAllEventChannel.on("onResetAll", () => {
      setBucketList((prev) =>
        prev.map((item) => ({ ...item, isSelected: false }))
      );
    });

    return () => {
      unsubscribeOnResetAll();
    };
  }, []);

  return (
    <div className="inline-block rounded-md bg-du-light-gray px-4 py-2">
      <p className="text-sm font-bold">Class Format</p>
      <p className="mb-2 text-sm">Select one or more</p>
      <div className="mx-auto max-w-[300px]">
        <div className="flex flex-wrap justify-around gap-2">
          {bucketList.map((bucket, idx) => (
            <button
              role="checkBox"
              id={`cs-class-format-${bucket.type.replaceAll(" ", "-")}`}
              key={idx}
              aria-checked={bucketList.includes(bucket)}
              className={clsx(
                "group rounded-md border border-du-black py-1.5 text-sm font-semibold capitalize",
                bucket.isSelected
                  ? "bg-du-black text-white"
                  : "bg-white text-du-black",
                bucket.type === "virtual synchronous" &&
                  campusCode === "ADLPH" &&
                  "invisible",
                bucket.type === "virtual synchronous"
                  ? "px-[10px] !text-[10px] !leading-[11px]"
                  : "px-4"
              )}
              onClick={() => {
                setBucketList((prev) =>
                  prev.map((item) => {
                    if (item.type === bucket.type) {
                      item.isSelected = !item.isSelected;
                    }
                    return item;
                  })
                );
              }}
            >
              <span className="invisible absolute -mt-8 ml-[70px] w-[200px] rounded bg-du-black px-2 py-1 text-sm normal-case text-white shadow-lg group-hover:visible group-hover:z-50">
                {bucket.description}
              </span>
              {bucket.type.split(" ").map((word, idx) => (
                <Fragment key={idx}>
                  {word}
                  <br />
                </Fragment>
              ))}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClassFormatField;
